@import url(https://fonts.googleapis.com/css?family=Spartan&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}.editable ul{list-style-type:disc}.editable:hover{background-color:rgba(255,192,0,0.8) !important;cursor:pointer}

.Root{font-family:'Spartan', sans-serif !important;display:flex;background:whitesmoke;min-width:100vw;min-height:100vh}.Column{display:flex;flex-direction:column}.Row{display:flex;flex-direction:row;flex-wrap:wrap}.Central{justify-content:center}

.Navbar{background-color:#222222;font-size:1em;padding:1em;border-bottom-left-radius:.5em;border-bottom-right-radius:.5em;justify-content:flex-start;align-content:center;box-shadow:0 3px 6px rgba(0,0,0,0.16),0 3px 6px rgba(0,0,0,0.23)}.Navbar-item a{margin:0;color:#9d9d9d;padding-left:1em;text-decoration:none}.Navbar-item{margin-top:auto;margin-bottom:auto}.Navbar-right{display:flex;justify-content:flex-end;margin-left:auto}.Navbar-right a{color:#007bff;padding:.5em;padding-left:1em;padding-right:1em;border-radius:1em;background-color:#383838}.Navbar-item-active a{color:whitesmoke}.Navbar-item:hover a{color:whitesmoke}.Brand{height:3em}

table{margin:1em;width:50vmax;border-collapse:collapse}tr{padding:2em}td{text-align:center;padding-top:1em;padding-bottom:1em;padding-left:10px;padding-right:10px;border-bottom:1px solid rgba(128,128,128,0.5)}.dietReportTable td{padding:1px}.dietReportTable tr:nth-child(2n){background-color:#f2f2f2}.dietReportTable tbody tr td:nth(n+1){background-color:black !important}

.chartjs-render-monitor{image-rendering:optimizeSpeed;image-rendering:-moz-crisp-edges;image-rendering:-webkit-optimize-contrast;image-rendering:-o-crisp-edges;image-rendering:crisp-edges;-ms-interpolation-mode:nearest-neighbor}

