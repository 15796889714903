@import url('https://fonts.googleapis.com/css?family=Spartan&display=swap');

.Root {
  font-family: 'Spartan', sans-serif !important;
  display: flex;
  background: whitesmoke;
  min-width: 100vw;
  min-height: 100vh;
}

.Column {
  display: flex;
  flex-direction: column;
}

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Central {
  justify-content: center;
}

